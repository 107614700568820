import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useRef, useState } from 'react';
import { flagFetch, setFlag } from '@/flag/flagSlice';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { CardActions, Typography } from '@mui/material';
import { AppBar, debounce, Loading } from '@/common';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { flagSave } from '@/flag/flagFormSlice';
import { agentListFetch } from '@/agent/agentListSlice';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';

export const FlagFormV2 = () => {
  const { flag, status, error } = useSelector((state) => state.flag);
  const { status: formStatus } = useSelector((state) => state.flagForm);
  const dispatch = useDispatch();
  const { parameter: urlParameter } = useParams();
  const [parameter, setParameter] = useState('');
  const [editMode, setEditMode] = useState(true);
  const [flagValueTypes, setFlagValueTypes] = useState([
    'value',
    'defaultValue',
  ]);
  const [isJson, setIsJson] = useState(false);
  const [fieldValues, setFieldValues] = useState(new Map());

  const initialFormState = {
    parameter: parameter,
    isJson: isJson,
    defaultValue: [],
    value: [],
  };
  const [formData, setFormData] = useState(initialFormState);
  const [addingNewInputValueForType, setAddingNewInputValueForType] =
    useState(null);
  const {
    error: agentsError,
    isLoading: agentsIsLoading,
    lastFilter: agentsLastFilter,
    agentList,
  } = useSelector((state) => state.agentList);
  const [multiFilter, setMultiFilter] = useState({
    isFetching: false,
    label: '',
    values: [],
  });
  const [agents, setAgents] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const prevInputValue = useRef('');

  useEffect(() => {
    if (
      !agentsError &&
      !agentsIsLoading &&
      (multiFilter.values.length > 0 || multiFilter.isFetching)
    ) {
      if (multiFilter.isFetching) {
        setAgents([...agents, ...agentList]);
        setMultiFilter((v) => ({ ...v, isFetching: false }));
      } else {
        const { label, values } = multiFilter;
        const len = values.length > 200 ? 200 : values.length;
        const params = values.slice(0, len);
        const search = new URLSearchParams(
          `pp=1:${len}&e=1&oo=agentName&ff=a.uuid,a.agentName&` +
            `${label}=isAnyOf:${params.join(',')}`
        );
        setMultiFilter({ isFetching: true, label, values: values.slice(len) });
        dispatch(agentListFetch(search));
      }
    }
  }, [dispatch, agents, multiFilter, agentsIsLoading, agentsError, agentList]);

  useEffect(() => {
    const handleSearch = debounce((value) => {
      const isMultiPhone = /^((\+\d+)(\s*,\s*|))+$/.test(value);
      const isMultiUuid =
        /^((\w{8,8}-\w{4,4}-\w{4,4}-\w{4,4}-\w{12,12})(\s*,\s*|))+$/.test(
          value
        );

      if (isMultiPhone || isMultiUuid) {
        const label = isMultiPhone ? 'phoneNumber' : 'uuid';
        setMultiFilter({
          isFetching: false,
          values: value.split(','),
          label,
        });
      } else {
        const search = new URLSearchParams(
          `pp=1:5&e=1&oo=agentName&ff=a.uuid,a.agentName` +
            `&agentName=contains:${value}`
        );
        dispatch(agentListFetch(search));
      }
    }, 500);

    if (inputValue && prevInputValue.current !== inputValue) {
      prevInputValue.current = inputValue;
      handleSearch(inputValue);
    }
  }, [inputValue, agentsLastFilter, dispatch]);

  useEffect(() => {
    if (
      (urlParameter !== `${flag?.parameter}` || flag?.agents === undefined) &&
      status !== 'loading' &&
      !error
    ) {
      if (urlParameter) {
        dispatch(flagFetch(urlParameter));
      }
    }
  }, [dispatch, flag, urlParameter, status, error]);

  useEffect(() => {
    if (urlParameter === `${flag?.parameter}`) {
      setParameter(flag?.parameter);
    }
  }, [flag, urlParameter]);

  useEffect(() => {
    setIsJson(flag?.isJson || false);
    updateFormDataAndFields(flag, isJson);
  }, [flag]);

  useEffect(() => {
    updateFormDataAndFields(flag, flag?.isJson);
  }, [isJson]);

  const getFormValues = (valueType) =>
    Array.isArray(formData[valueType]) ? formData[valueType] : [];

  const handleNewValue = (value, valueType) => {
    const values = [...getFormValues(valueType), value];
    const newFormData = { ...formData, [valueType]: values };
    setFormData(newFormData);
    const updatedMap = new Map();
    flagValueTypes.forEach((vt) => {
      (newFormData[vt] || []).forEach((v) => {
        updatedMap.set(getInputMapKey(vt, v.key), v.value);
      });
    });
    setFieldValues(updatedMap);
    setFlagValueTypes(['value', 'defaultValue']);
    setAddingNewInputValueForType(null);
  };

  const removeValue = (value, valueType) => {
    const values = getFormValues(valueType).filter(
      (item) => item.key !== value.key
    );
    const newFormData = { ...formData, [valueType]: values };
    setFormData(newFormData);
    const updatedMap = new Map();
    flagValueTypes.forEach((vt) => {
      (newFormData[vt] || []).forEach((v) => {
        updatedMap.set(getInputMapKey(vt, v.key), v.value);
      });
    });
    setFieldValues(updatedMap);
    setFlagValueTypes(['value', 'defaultValue']);
  };

  const updateFormDataAndFields = (flag, isJson) => {
    if (!flag) return setEditMode(true);

    const defaultValue = parseParameterValues(flag.defaultValue, isJson);
    const value = parseParameterValues(flag.value, isJson);
    const newFormData = { parameter: flag.parameter, defaultValue, value };

    const updatedMap = new Map();
    value.forEach((v) =>
      updatedMap.set(getInputMapKey('value', v.key), v.value)
    );
    defaultValue.forEach((v) =>
      updatedMap.set(getInputMapKey('defaultValue', v.key), v.value)
    );

    setFormData(newFormData);
    setFieldValues(updatedMap);
    setParameter(flag.parameter);
  };

  const parseParameterValues = (valuesString, isJson) => {
    if (isJson) {
      try {
        return JSON.parse(valuesString) || [];
      } catch {
        return [];
      }
    }
    return valuesString ? [{ key: 'value', value: valuesString }] : [];
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const getValueType = (value) => {
    if (value === 'true' || value === 'false') {
      return 'checkbox';
    } else {
      return 'text';
    }
  };

  const handleIsJsonChange = (e) => {
    const checked = e.target.checked;
    setIsJson(checked);
    setFormData({ ...formData, isJson: checked });
  };

  const handleInputChange = (mapKey) => (e) => {
    const newValue =
      e.target.type === 'checkbox'
        ? e.target.checked.toString()
        : e.target.value;

    setFieldValues((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.set(mapKey, newValue);
      return updatedMap;
    });

    setFormData((prev) => {
      const [valueType, key] = mapKey.split('-');
      return {
        ...prev,
        [valueType]: prev[valueType].map((item) =>
          item.key === key ? { ...item, value: newValue } : item
        ),
      };
    });
  };

  const getInputMapKey = (valueType, valueKey) => {
    return `${valueType}-${valueKey}`;
  };

  const handleSaveFlag = () => (e) => {
    e.preventDefault();
    const newFlag = {
      ...formData,
      parameter: urlParameter ?? parameter,
      isJson,
      agents,
    };
    flagValueTypes.forEach((valueType) => {
      if (newFlag.hasOwnProperty(valueType) && isJson) {
        newFlag[valueType] = JSON.stringify(newFlag[valueType]);
      }
    });
    dispatch(flagSave(urlParameter, newFlag));
    console.log(`>>> Saving flag: `, newFlag);
  };

  const w = (callback) => (e) => callback(e.target.value);

  const cancelEditMode = () => {
    if (!!addingNewInputValueForType) {
      setAddingNewInputValueForType(null);
    } else {
      setEditMode(false);
    }
  };

  return (
    <>
      {formStatus === 'loading' || status === 'loading' ? (
        <Loading />
      ) : (
        <Stack direction="column">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{ pt: 1, pb: 2 }}
          >
            <Box sx={{ marginRight: 'auto' }}></Box>
            {!editMode && (
              <Button
                disableElevation
                variant="contained"
                component={RouterLink}
                type="button"
                onClick={() => toggleEditMode()}
              >
                Edit
              </Button>
            )}
          </Box>

          <Card>
            <CardContent>
              <Stack direction="column">
                <TextField
                  placeholder="Parameter"
                  label="Parameter"
                  value={parameter}
                  onChange={w(setParameter)}
                  helperText="Parameter must be alphanumeric string with optional underscores"
                  disabled={!!urlParameter}
                  required
                />
                <StyledCheckbox
                  sx={{ mt: 2 }}
                  label="Is JSON"
                  checked={isJson}
                  onChange={handleIsJsonChange}
                  disabled={!editMode}
                />
                <Divider sx={{ mt: 3, mb: 3 }} />
                <Grid container spacing={2}>
                  {flagValueTypes.map((valueType) => (
                    <Grid item xs={6}>
                      <List>
                        {getFormValues(valueType).map((value, index) => {
                          const mapKey = getInputMapKey(valueType, value.key);
                          const valueFieldType = getValueType(value.value);
                          if (index > 0 && !isJson) return <></>;

                          return (
                            <ListItem key={mapKey}>
                              {valueFieldType === 'text' && (
                                <TextField
                                  fullWidth
                                  label={`${value.key}`}
                                  value={fieldValues.get(mapKey)}
                                  onChange={handleInputChange(mapKey)}
                                  disabled={!editMode}
                                  InputProps={
                                    editMode
                                      ? {
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton
                                                onClick={() =>
                                                  removeValue(value, valueType)
                                                }
                                                edge="end"
                                                size="small"
                                              >
                                                <CloseIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }
                                      : {}
                                  }
                                />
                              )}
                              {valueFieldType === 'checkbox' && (
                                <StyledCheckbox
                                  label={value.key}
                                  checked={fieldValues.get(mapKey) === 'true'}
                                  onChange={handleInputChange(mapKey)}
                                  disabled={!editMode}
                                  editMode={editMode}
                                  closeClicked={() =>
                                    removeValue(value, valueType)
                                  }
                                />
                              )}
                            </ListItem>
                          );
                        })}
                        {addingNewInputValueForType === valueType && (
                          <ListItem>
                            <NewInputValue
                              onSaveValue={handleNewValue}
                              valueType={valueType}
                            />
                          </ListItem>
                        )}
                        {editMode &&
                          !addingNewInputValueForType &&
                          (isJson || getFormValues(valueType).length === 0) && (
                            <ListItem>
                              <Stack direction="row-reverse">
                                <Button
                                  onClick={() =>
                                    setAddingNewInputValueForType(valueType)
                                  }
                                >
                                  Add Value
                                </Button>
                              </Stack>
                            </ListItem>
                          )}
                      </List>
                    </Grid>
                  ))}
                </Grid>
              </Stack>

              <Divider sx={{ mt: 3, mb: 3 }} />

              <FormControl fullWidth sx={{ mb: 2 }}>
                <Autocomplete
                  getOptionLabel={(agent) =>
                    typeof agent == 'string'
                      ? agent
                      : `${agent?.agentName} (${agent?.uuid})`
                  }
                  filterOptions={(x) => x}
                  options={agentList}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  value={agents}
                  multiple={true}
                  isOptionEqualToValue={(option, value) => {
                    return option.uuid === value.uuid;
                  }}
                  onChange={(_, newValue) => {
                    setAgents(newValue);
                  }}
                  onInputChange={(_, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`Agents (${agents?.length ?? 0})`}
                      error={!!agentsError}
                      helperText={
                        agentsError
                          ? agentsError
                          : 'Use a comma separated list of agent UUIDs or phone numbers to bulk add'
                      }
                    />
                  )}
                />
              </FormControl>

              <Divider sx={{ mt: 3, mb: 3 }} />
            </CardContent>
            {editMode && (
              <CardActions>
                <Button variant="contained" onClick={handleSaveFlag()}>
                  Save
                </Button>
              </CardActions>
            )}
          </Card>
        </Stack>
      )}
    </>
  );
};

const StyledCheckbox = ({
  label,
  checked,
  onChange,
  closeClicked,
  disabled,
  sx = {},
  editMode,
}) => {
  return (
    <Box
      sx={Object.assign(
        {
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #ced4da',
          borderRadius: '4px',
          padding: '10px 14px',
          width: '100%',
        },
        sx
      )}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            sx={{
              padding: 0,
              mr: 2,
            }}
          />
        }
        label={
          <Typography
            sx={{
              fontSize: '16px',
              mr: 'auto',
              flex: 1,
            }}
          >
            {label}
          </Typography>
        }
        sx={{
          width: '100%',
          margin: 0,
        }}
      />
      {editMode && (
        <IconButton onClick={closeClicked} edge="end" size="small">
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
};

const NewInputValue = ({ onSaveValue, valueType }) => {
  const types = ['checkbox', 'text'];
  const [type, setType] = useState('text');
  const [key, setKey] = useState('');
  const [value, setValue] = useState('');
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    setCanSave(key.length > 1 && value.length > 1);
  }, [key, value]);

  const changeType = (e) => {
    const type = e.target.value;
    setValue('');
    setType(type);
  };

  return (
    <Stack
      direction="row"
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #ced4da',
        borderRadius: '4px',
        padding: '10px 14px',
        width: '100%',
      }}
    >
      <TextField
        required
        sx={{ mr: 2 }}
        label="Key"
        onChange={(e) => setKey(e.target.value)}
        value={key}
      />

      <Select
        value={type}
        onChange={changeType}
        variant="outlined"
        sx={{
          mr: 2,
          minWidth: 120,
          height: '40px',
          borderRadius: '4px',
          backgroundColor: 'white',
        }}
      >
        {types.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      <Box sx={{ mr: 'auto' }}>
        {type === 'checkbox' && (
          <FormControlLabel
            control={
              <Checkbox
                checked={value === 'true'}
                onChange={(e) => {
                  setValue(e.target.checked.toString());
                }}
                sx={{
                  padding: 0,
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: '16px',
                  flex: 1,
                }}
              >
                {''}
              </Typography>
            }
            sx={{
              width: '100%',
              margin: 0,
            }}
          />
        )}
        {type === 'text' && (
          <TextField
            label="value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        )}
      </Box>

      <Button
        variant="contained"
        disabled={!canSave}
        onClick={() => onSaveValue({ key, value }, valueType)}
      >
        Add
      </Button>
    </Stack>
  );
};
