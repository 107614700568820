import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppBar } from '@/common';
import { useSelector } from 'react-redux';
import { FlagForm } from '@/flag/FlagForm';
import { FlagFormV2 } from '@/flag/FlagFormV2';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DataObjectIcon from '@mui/icons-material/DataObject';
import IconButton from '@mui/material/IconButton';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import Tooltip from '@mui/material/Tooltip';

export const FlagFormHolder = () => {
  const [formType, setFormType] = useState('form');
  const [parameter, setParameter] = useState('');
  const { flag } = useSelector((state) => state.flag);
  const { parameter: urlParameter } = useParams();

  useEffect(() => {
    if (urlParameter === `${flag?.parameter}`) {
      setParameter(flag?.parameter);
    }
  }, [flag, urlParameter]);
  const toggleFormType = () => {
    setFormType(formType === 'form' ? 'json' : 'form');
  };

  return (
    <>
      <AppBar>
        <Stack direction="row" spacing={2}>
          <span style={{ marginRight: 'auto' }}>
            {parameter ? `Edit Flag: ${flag?.parameter}` : 'Create Flag'}
          </span>
          {formType === 'json' && (
            <Tooltip title="Switch to Form View">
              <IconButton onClick={toggleFormType} size="small" color="primary">
                <VerticalSplitIcon />
              </IconButton>
            </Tooltip>
          )}
          {formType === 'form' && (
            <Tooltip title="Switch to JSON View">
              <IconButton onClick={toggleFormType} size="small" color="primary">
                <DataObjectIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </AppBar>
      {formType === 'form' && <FlagFormV2 />}
      {formType === 'json' && <FlagForm />}
    </>
  );
};
