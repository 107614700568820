import { agentListFetch } from '@/agent/agentListSlice';
import { AppBar, Error, Loading } from '@/common';
import { Backdrop } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { debounce } from '@/common';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { flagSave, resetState } from './flagFormSlice';
import { flagFetch } from './flagSlice';
import { captureEvent } from '@/firebase';

export const FlagForm = () => {
  // console.log('RXD:FlagForm');
  const dispatch = useDispatch();
  const { parameter: urlParameter } = useParams();

  const { flag, status, error } = useSelector((state) => state.flag);

  const { error: formError, status: formStatus } = useSelector(
    (state) => state.flagForm
  );

  const {
    error: agentsError,
    isLoading: agentsIsLoading,
    lastFilter: agentsLastFilter,
    agentList,
  } = useSelector((state) => state.agentList);

  const [multiFilter, setMultiFilter] = useState({
    isFetching: false,
    label: '',
    values: [],
  });
  const prevInputValue = useRef('');
  const [parameter, setParameter] = useState('');
  const [isJson, setIsJson] = useState(true);
  const [value, setValue] = useState('');
  const [defaultValue, setDefaultValue] = useState('');
  const [agents, setAgents] = useState([]);
  const [inputValue, setInputValue] = useState('');

  // console.log('RXD:FlagForm', { urlParameter, flag, status, error });

  useEffect(() => {
    // console.log('RXD: FlagForm: useEffect() for change');
    if (
      (urlParameter !== `${flag?.parameter}` || flag?.agents === undefined) &&
      status !== 'loading' &&
      !error
    ) {
      // console.log('RXD: FlagForm resetState()');
      dispatch(resetState());
      setParameter('');
      setIsJson(true);
      setValue('');
      setDefaultValue('');
      setAgents([]);
      if (urlParameter) {
        dispatch(flagFetch(urlParameter));
      }
    }
  }, [dispatch, flag, urlParameter, status, error]);

  // initial values
  useEffect(() => {
    if (urlParameter === `${flag?.parameter}`) {
      // console.log('RXD: FlagForm initial values', { urlParameter, flag });
      setParameter(flag?.parameter);
      setIsJson(flag?.isJson);
      setValue(flag?.value);
      setDefaultValue(flag?.defaultValue);
      setAgents(flag?.agents ?? []);
    }
  }, [flag, urlParameter]);

  useEffect(() => {
    if (
      !agentsError &&
      !agentsIsLoading &&
      (multiFilter.values.length > 0 || multiFilter.isFetching)
    ) {
      if (multiFilter.isFetching) {
        setAgents([...agents, ...agentList]);
        setMultiFilter((v) => ({ ...v, isFetching: false }));
      } else {
        const { label, values } = multiFilter;
        const len = values.length > 200 ? 200 : values.length;
        const params = values.slice(0, len);
        const search = new URLSearchParams(
          `pp=1:${len}&e=1&oo=agentName&ff=a.uuid,a.agentName&` +
            `${label}=isAnyOf:${params.join(',')}`
        );
        setMultiFilter({ isFetching: true, label, values: values.slice(len) });
        dispatch(agentListFetch(search));
      }
    }
  }, [dispatch, agents, multiFilter, agentsIsLoading, agentsError, agentList]);

  useEffect(() => {
    const handleSearch = debounce((value) => {
      const isMultiPhone = /^((\+\d+)(\s*,\s*|))+$/.test(value);
      const isMultiUuid =
        /^((\w{8,8}-\w{4,4}-\w{4,4}-\w{4,4}-\w{12,12})(\s*,\s*|))+$/.test(
          value
        );

      if (isMultiPhone || isMultiUuid) {
        const label = isMultiPhone ? 'phoneNumber' : 'uuid';
        setMultiFilter({
          isFetching: false,
          values: value.split(','),
          label,
        });
      } else {
        const search = new URLSearchParams(
          `pp=1:5&e=1&oo=agentName&ff=a.uuid,a.agentName` +
            `&agentName=contains:${value}`
        );
        dispatch(agentListFetch(search));
      }
    }, 500);

    if (inputValue && prevInputValue.current !== inputValue) {
      // console.log('hnadleSearch');
      prevInputValue.current = inputValue;
      handleSearch(inputValue);
    }
  }, [inputValue, agentsLastFilter, dispatch]);

  const isParameterInvalid = (parameter) =>
    parameter && !/^[0-9a-zA-z_]+$/.test(parameter);

  const handleSave = (e) => {
    e.preventDefault();
    if (isParameterInvalid(parameter)) {
      return;
    }
    const data = {
      parameter: urlParameter ?? parameter,
      isJson,
      agents,
      value,
      defaultValue,
    };
    dispatch(flagSave(urlParameter, data));
    captureEvent(parameter ? 'EDIT_FLAG_FORM_SAVE' : 'ADD_FLAG_FORM_SAVE', {});
  };

  const w = (callback) => (e) => callback(e.target.value);

  // console.log('RXD: FlagForm', { flag });

  return (
    <>
      {formError && <Error error={formError} />}

      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={multiFilter.isFetching}
      >
        <Loading />
      </Backdrop>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {error ? (
                <Error
                  error={error}
                  onRetry={() => dispatch(flagFetch(parameter))}
                />
              ) : status === 'loading' ? (
                <Loading />
              ) : (
                <Box
                  component="form"
                  method="POST"
                  sx={{ p: 1 }}
                  onSubmit={handleSave}
                  autoComplete="off"
                >
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      id="parameter"
                      label="Parameter"
                      variant="outlined"
                      required
                      disabled={!!urlParameter}
                      value={parameter}
                      onChange={w(setParameter)}
                      error={!!isParameterInvalid(parameter)}
                      helperText="Parameter must be alphanumeric string with optional underscores"
                    />
                  </FormControl>
                  <FormControlLabel
                    sx={{ mb: 2 }}
                    control={
                      <Switch
                        checked={isJson}
                        onChange={(_, t) => setIsJson(t)}
                        name="isJson"
                      />
                    }
                    label="Is JSON"
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <TextField
                          id="value"
                          label="Value"
                          variant="outlined"
                          required
                          value={value}
                          onChange={w(setValue)}
                          multiline
                          helperText="The value agents in this audience will receive"
                          minRows={10}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <TextField
                          id="defaultValue"
                          label="Default Value"
                          variant="outlined"
                          required
                          value={defaultValue}
                          onChange={w(setDefaultValue)}
                          multiline
                          helperText="The default value that other agents NOT in the audience will receive"
                          minRows={10}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <Autocomplete
                      getOptionLabel={(agent) =>
                        typeof agent == 'string'
                          ? agent
                          : `${agent?.agentName} (${agent?.uuid})`
                      }
                      filterOptions={(x) => x}
                      options={agentList}
                      autoComplete
                      includeInputInList
                      filterSelectedOptions
                      value={agents}
                      multiple={true}
                      isOptionEqualToValue={(option, value) => {
                        return option.uuid === value.uuid;
                      }}
                      onChange={(_, newValue) => {
                        setAgents(newValue);
                      }}
                      onInputChange={(_, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`Agents (${agents?.length ?? 0})`}
                          error={!!agentsError}
                          helperText={
                            agentsError
                              ? agentsError
                              : 'Use a comma separated list of agent UUIDs or phone numbers to bulk add'
                          }
                        />
                      )}
                    />
                  </FormControl>

                  <Divider sx={{ mb: 2 }} />

                  {formStatus === 'loading' ? (
                    <Loading />
                  ) : (
                    <Button
                      disableElevation
                      variant="contained"
                      sx={{ mb: 2, textTransform: 'none' }}
                      type="submit"
                    >
                      Save
                    </Button>
                  )}
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
