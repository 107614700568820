import {
  AppBar,
  BooleanBubble,
  Bubble,
  Error,
  getColumnVisibility,
  getExternalIdLabel,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { FEATURES, hasFeature } from '@/common/features';
import { paginationValue } from '@/common/filter';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { agentListExport, agentListFetch } from './agentListSlice';
import { setAgent } from './agentSlice';
import {
  formattedCount,
  formattedVolume,
  getAgentStatus,
  getAgentStatusBackground,
  getAgentStatusTextColor,
  getAgentStatuses,
  getBirthday,
  getBusinessTypes,
  getFieldAgent,
  getKyaFieldAgent,
  getOnboardedFieldAgent,
  getPhysicalLocation,
  getReferredByOptions,
  getTradingName,
  lastActiveAt,
} from './utils';

export const AgentList = ({ embedded }) => {
  // console.log('RXD:AgentList');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { agentList, isLoading, error, lastFilter } = useSelector((state) => {
    return state.agentList;
  });

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);

  const w = (callback) => (param) => callback(param.row);

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, [
      'agentUuid',
      'fa.uuid',
    ]);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', '-transactionVolume');
    }
    if (
      !currentFilters.has('status') &&
      !currentFilters.has('c.status') &&
      hasFeature(FEATURES.AGENT_SHOW_ACTIVE)
    ) {
      currentFilters.set('status', 'is:active');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(agentListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    ...(hasFeature(FEATURES.AGENT_COMPLIANCE)
      ? [
          {
            field: 'c.status',
            headerName: 'Compliance Status',
            minWidth: 150,
            type: 'singleSelect',
            valueOptions: getAgentStatuses().map((status) => ({
              label: status,
              value: status,
            })),
            sortingOrder: getSortOrder(),
            renderCell: w((agent) => (
              <Bubble
                label={getAgentStatus(agent)}
                background={getAgentStatusBackground(agent)}
                color={getAgentStatusTextColor(agent)}
              />
            )),
          },
          {
            field: 'isVerified',
            headerName: 'Is Active',
            type: 'boolean',
            sortingOrder: getSortOrder(),
            flex: 0.5,
            minWidth: 100,
            renderCell: w((agent) => (
              <BooleanBubble value={agent?.isVerified} />
            )),
          },
        ]
      : []),
    ...[
      {
        field: 'agentName',
        headerName: 'Name',
        minWidth: 200,
        sortingOrder: getSortOrder(),
      },
      {
        field: 'fa.name',
        headerName: 'Field Agent',
        sortingOrder: getSortOrder(),
        valueGetter: w(getFieldAgent),
        minWidth: 200,
      },
      ...(hasFeature(FEATURES.AGENT_EXTERNAL_ID)
        ? [
            {
              field: 'pl.name',
              headerName: 'Territory',
              sortingOrder: getSortOrder(),
              valueGetter: w(getPhysicalLocation),
              minWidth: 200,
            },
            {
              field: 'pl.id',
              headerName: 'Territory ID',
              sortingOrder: getSortOrder(),
              minWidth: 200,
            },
            {
              field: 'externalId',
              headerName: getExternalIdLabel(),
              sortingOrder: getSortOrder(),
              flex: 0.5,
              minWidth: 100,
            },
          ]
        : []),
      {
        field: 'kyafa.name',
        headerName: 'KYA Field Agent',
        sortingOrder: getSortOrder(),
        valueGetter: w(getKyaFieldAgent),
        minWidth: 160,
      },
      {
        field: 'ofa.name',
        headerName: 'Onboarded Field Agent',
        sortingOrder: getSortOrder(),
        valueGetter: w(getOnboardedFieldAgent),
        minWidth: 160,
      },
      {
        field: 'transactionCount',
        headerName: 'Transactions',
        align: 'right',
        headerAlign: 'left',
        type: 'number',
        sortingOrder: getSortOrder(),
        valueGetter: w(formattedCount),
        minWidth: 150,
        flex: 0.7,
      },
      {
        field: 'transactionVolume',
        headerName: 'Volume',
        align: 'right',
        headerAlign: 'left',
        type: 'number',
        sortingOrder: getSortOrder(),
        valueGetter: w(formattedVolume),
        minWidth: 160,
        flex: 1,
      },
      {
        field: 'gender',
        headerName: 'Gender',
        align: 'left',
        headerAlign: 'left',
        type: 'singleSelect',
        valueOptions: [
          { value: 'Female', label: 'Female' },
          { value: 'Male', label: 'Male' },
        ],
        sortingOrder: getSortOrder(),
        minWidth: 100,
      },
      {
        field: 'birthday',
        headerName: 'Birthday',
        sortingOrder: getSortOrder(),
        valueGetter: w(getBirthday),
        minWidth: 140,
        flex: 0.5,
      },
      {
        field: 'appVersion',
        headerName: 'App Version',
        align: 'center',
        headerAlign: 'center',
        sortingOrder: getSortOrder(),
        minWidth: 100,
        flex: 0.7,
      },
      {
        field: 'emailAddress',
        headerName: 'Email',
        align: 'center',
        headerAlign: 'center',
        sortingOrder: getSortOrder(),
        minWidth: 100,
        flex: 0.7,
      },
      ...(hasFeature(FEATURES.AGENT_VERIFICATION)
        ? [
            {
              field: 'isVerified',
              headerName: 'Verified',
              type: 'boolean',
              sortingOrder: getSortOrder(),
              flex: 0.5,
              minWidth: 100,
              renderCell: w((agent) => (
                <BooleanBubble value={agent?.isVerified} />
              )),
            },
            {
              field: 'ownerType',
              headerName: 'Ownership',
              minWidth: 160,
              flex: 1,
            },
          ]
        : []),
      ...(hasFeature(FEATURES.AGENT_COMPLIANCE)
        ? [
            {
              field: 'ownerType',
              headerName: 'Agent Type',
              minWidth: 160,
              flex: 1,
            },
          ]
        : []),
      {
        field: 'lastActiveAt',
        headerName: 'Last Active',
        align: 'left',
        headerAlign: 'left',
        valueGetter: w(lastActiveAt),
        type: 'dateTime',
        sortingOrder: getSortOrder(),
        minWidth: 190,
        flex: 1,
      },
      {
        field: 'phoneNumber',
        headerName: 'Phone Number',
        type: 'text',
        minWidth: 190,
        flex: 1,
        valueGetter: w((agent) => agent.simNumbers[0]?.phoneNumber ?? ''),
      },
      {
        field: 'nationality',
        headerName: 'Nationality',
        type: 'text',
        minWidth: 160,
        flex: 1,
      },
      {
        field: 'nationalId',
        headerName: 'National ID',
        type: 'text',
        minWidth: 160,
        flex: 1,
      },
      {
        field: 'cardNumber',
        headerName: 'Card Number',
        type: 'text',
        minWidth: 160,
        flex: 1,
      },
      {
        field: 'address',
        headerName: 'Address',
        type: 'text',
        minWidth: 160,
        flex: 1,
      },
      {
        field: 'location',
        headerName: 'Location',
        type: 'text',
        minWidth: 160,
        flex: 1,
      },
      {
        field: 'country',
        headerName: 'Country',
        type: 'text',
        minWidth: 160,
        flex: 1,
      },

      ...(hasFeature(FEATURES.AGENT_VERIFICATION)
        ? [
            {
              field: 'businessType',
              headerName: 'Business',
              type: 'singleSelect',
              valueOptions: getBusinessTypes().map((type) => ({
                value: type,
                label: type,
              })),
              minWidth: 160,
              flex: 1,
            },
            {
              field: 'isPrimaryIncome',
              headerName: 'Primary Income',
              type: 'boolean',
              minWidth: 100,
              renderCell: w((agent) => (
                <BooleanBubble value={agent?.isPrimaryIncome} />
              )),
            },
            {
              field: 'levelOfEducation',
              headerName: 'Education',
              type: 'text',
              minWidth: 160,
              flex: 1,
            },
            {
              field: 'nextOfKinFullName',
              headerName: 'Next of kin',
              type: 'text',
              minWidth: 160,
              flex: 1,
            },
            {
              field: 'nextOfKinRelationship',
              headerName: 'N.O.K relationship',
              type: 'text',
              minWidth: 160,
              flex: 1,
            },
            {
              field: 'nextOfKinPhoneNumber',
              headerName: 'N.O.K phone number',
              type: 'text',
              minWidth: 160,
              flex: 1,
            },
            {
              field: 'referredBy',
              headerName: 'Referral',
              type: 'singleSelect',
              valueOptions: getReferredByOptions().map((type) => ({
                value: type,
                label: type,
              })),
              minWidth: 120,
              flex: 1,
            },
            {
              field: 'referredDetail',
              headerName: 'Referrer',
              type: 'text',
              minWidth: 160,
              flex: 1,
            },
          ]
        : []),
      ...(hasFeature(FEATURES.AGENT_COMPLIANCE)
        ? [
            {
              field: 'nextOfKinFullName',
              headerName: 'Emergency contact',
              type: 'text',
              minWidth: 160,
              flex: 1,
            },
            {
              field: 'nextOfKinRelationship',
              headerName: 'Emergency contact relationship',
              type: 'text',
              minWidth: 160,
              flex: 1,
            },
            {
              field: 'nextOfKinPhoneNumber',
              headerName: 'Emergency contact phone number',
              type: 'text',
              minWidth: 160,
              flex: 1,
            },

            {
              field: 'c.fraudReason',
              headerName: 'Fraud',
              type: 'text',
              minWidth: 160,
              flex: 1,
              valueGetter: w((agent) => agent?.contract?.fraudReason),
            },
            {
              field: 'c.friendAtOrganization',
              headerName: 'Relative in Africell',
              type: 'text',
              minWidth: 160,
              flex: 1,
              valueGetter: w((agent) => agent?.contract?.friendAtOrganization),
            },
            {
              field: 'c.politicallyExposedPerson',
              headerName: 'Related to PEP',
              type: 'text',
              minWidth: 160,
              flex: 1,
              valueGetter: w(
                (agent) => agent?.contract?.politicallyExposedPerson
              ),
            },
          ]
        : []),
      {
        field: 'updatedAt',
        headerName: 'Updated At',
        align: 'center',
        headerAlign: 'center',
        type: 'dateTime',
        sortingOrder: getSortOrder(),
        minWidth: 190,
        flex: 1,
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        align: 'center',
        headerAlign: 'center',
        type: 'dateTime',
        sortingOrder: getSortOrder(),
        minWidth: 190,
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Agent Status',
        align: 'center',
        headerAlign: 'center',
        minWidth: 190,
        flex: 1,
        type: 'singleSelect',
        valueOptions: [
          { value: 'active', label: 'Active' },
          { value: 'inactive', label: 'In active' },
        ],
      },
      {
        field: 'uuid',
        headerName: 'UUID',
        align: 'center',
        headerAlign: 'center',
        sortingOrder: getSortOrder(),
        minWidth: 190,
        flex: 1,
      },
    ],
    ...(hasFeature(FEATURES.AGENT_TRADING_NAME)
      ? [
          {
            field: 'tradingName',
            headerName: 'Trading Name',
            type: 'text',
            valueGetter: w(getTradingName),
          },
          {
            field: 'createdByAgentDiscoveryForm',
            headerName: 'From Discovery',
            type: 'boolean',
            minWidth: 100,
            renderCell: w((agent) => (
              <BooleanBubble
                value={agent?.additionalData?.createdByAgentDiscoveryForm}
              />
            )),
          },
        ]
      : []),
  ];

  return (
    <>
      {!embedded && <AppBar>Mobile Money Agents</AppBar>}

      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(agentListFetch(filters))}
        />
      )}

      <div style={{ height: 'calc(100vh - 190px)', minHeight: 300 }}>
        <OparetaTable
          getRowId={(agent) => agent.uuid}
          rows={agentList ?? []}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('AgentList', visible)
          }
          onCellClick={(param, e) => {
            const agent = param.row;
            if (e.ctrlKey) {
              window.open(`/agents/${agent.uuid}`);
            } else {
              dispatch(setAgent(agent));
              navigate(`/agents/${agent.uuid}`);
            }
          }}
          onExportClick={() => dispatch(agentListExport(filters))}
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            // pagination: { paginationModel: { page } },
            sorting: getInitialSortModel(filters, columns, [
              { field: 'transactionVolume', sort: 'desc' },
            ]),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility('AgentList', {
                birthday: false,
                onboardedFieldAgent: false,
                onboardedFieldAgentUuid: false,
                phoneNumber: false,
                emailAddress: false,
                businessType: false,
                ownerType: false,
                isPrimaryIncome: false,
                nationality: false,
                nationalId: false,
                cardNumber: false,
                address: false,
                location: false,
                country: false,
                levelOfEducation: false,
                nextOfKinFullName: false,
                nextOfKinRelationship: false,
                nextOfKinPhoneNumber: false,
                referredBy: false,
                referredDetail: false,
                verifiededAt: false,
                updatedAt: false,
                createdAt: false,
                uuid: false,
                'pl.id': false,
              }),
            },
          }}
          columns={columns}
        />
      </div>
    </>
  );
};
