import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import VerifiedIcon from '@mui/icons-material/Verified';
import MuiTooltip from '@mui/material/Tooltip';

export const transformRequest = (request) => {
  const { user, agent, assignedFieldAgent } = request;
  let initiatedBy = null;
  let initiatorDetails = null;
  // Field agent assigned to the request or one assigned to the agent
  const fieldAgent = assignedFieldAgent || agent.fieldAgent;
  if (user?.agent && user.agent?.uuid === agent?.uuid) {
    // Agent-initiated request
    initiatedBy = 'AGENT';
    initiatorDetails = user.agent;
  } else if (user?.fieldAgent) {
    if (user?.fieldAgent.uuid === fieldAgent?.uuid) {
      // Assigned field agent
      initiatedBy = 'FIELD_AGENT';
    } else {
      // Other field agent
      initiatedBy = 'OTHER_FIELD_AGENT';
    }
    initiatorDetails = user.fieldAgent;
  } else {
    // Super agent initiated
    // Not expected to happen but just in case
    initiatedBy = 'SUPER_AGENT';
    initiatorDetails = {
      uuid: user?.agent?.uuid,
      name: user?.agent?.agentName,
    };
  }

  return {
    ...request,
    initiatedBy,
    initiatorDetails: {
      uuid: initiatorDetails.uuid,
      name: initiatorDetails.agentName || initiatorDetails.name,
    },
  };
};

export const getRequestInitiatorIcon = (initiatorType) => {
  const iconStyles = {
    display: 'inline-block',
    textAlign: 'center',
    width: '15px',
    lineHeight: '15px',
    height: '15px',
    borderRadius: '4px',
    marginRight: '2px',
    fontWeight: 'bold',
  };
  switch (initiatorType) {
    case 'AGENT':
      return (
        <MuiTooltip sx={iconStyles} title="Agent Initiated">
          <VerifiedIcon color="primary" />
        </MuiTooltip>
      );
    case 'FIELD_AGENT':
      return (
        <MuiTooltip sx={iconStyles} title="Assigned Field Agent Initiated">
          <SupervisorAccountIcon color="success" />
        </MuiTooltip>
      );
    case 'OTHER_FIELD_AGENT':
      return (
        <MuiTooltip sx={iconStyles} title="Other Field Agent Initiated">
          <HelpOutlineIcon color="warning" />
        </MuiTooltip>
      );
    default:
      return (
        <MuiTooltip sx={iconStyles} title="Super Agent-Initiated">
          <TravelExploreIcon color="primary" />
        </MuiTooltip>
      );
  }
};
