import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { AppBar, Loading, makeUnique } from '@/common';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { getFloatCashTransactionTypeTitle } from '@/float-cash/util';
import { useDispatch, useSelector } from 'react-redux';
import {
  cashTransactionUpdate,
  floatCashTransactionFetch,
} from '@/float-cash/floatCashTransactionEditFormSlice';
import {
  removeUploadFile,
  uploadAttachmentsFile,
} from '@/float-cash/floatCashTransactionEditFormSlice';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

export const FloatCashTransactionEditForm = ({ embedded }) => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const currencyCode = import.meta.env?.VITE_CURRENCY_CODE || 'UGX';
  const [amount, setAmount] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [comment, setComment] = useState('');
  const [depositSlip, setDepositSlip] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const {
    transaction,
    attachmentUrl,
    isLoading,
    isUploadingFiles,
    isSubmitting,
  } = useSelector((state) => state.floatCashTransactionEditForm);
  const w = (callback) => (e) => callback(e.target.value);

  useEffect(() => {
    dispatch(floatCashTransactionFetch(uuid));
  }, []);

  useEffect(() => {
    setAmount(transaction?.amount?.toString());
    setTransactionId(transaction?.transactionId);
  }, [transaction]);

  useEffect(() => {
    if (!selectedFile) return;
    const fileFormData = new FormData();
    fileFormData.append('file', selectedFile);
    dispatch(uploadAttachmentsFile(fileFormData));
  }, [selectedFile]);

  useEffect(() => {
    setDepositSlip(
      !!attachmentUrl ? attachmentUrl : (transaction?.attachments || [])[0]
    );
    console.log('>>> deposit slip: ', depositSlip);
  }, [attachmentUrl, transaction]);

  const handleFileChange = (e) => {
    const file = e.target?.files[0];
    console.log('>>> Handing file change. File: ', file);
    setSelectedFile(file);
  };

  const handleRemoveAttachment = () => {
    dispatch(removeUploadFile());
  };

  const handleSave = (e) => {
    e.preventDefault();
    console.log('>>> Saving');
    const data = {
      currency: currencyCode,
      amount: +amount,
      transactionId: transactionId,
    };
    if (comment != null && comment.trim().length > 0) {
      data.comments = [comment];
    }
    if (attachmentUrl != null && transaction.transactionType === 'DEPOSIT') {
      data.attachments = [attachmentUrl];
    }
    dispatch(cashTransactionUpdate(uuid, data));
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!embedded && (
            <AppBar>
              Edit{' '}
              {transaction
                ? getFloatCashTransactionTypeTitle(transaction)
                : 'Transaction'}
              : {uuid}
            </AppBar>
          )}
          {transaction ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    {
                      <Box
                        component="form"
                        method="POST"
                        sx={{ p: 1 }}
                        onSubmit={handleSave}
                        autoComplete="off"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            <FormControl fullWidth sx={{ my: 2 }}>
                              <TextField
                                id="amount"
                                label="Amount"
                                variant="outlined"
                                type="number"
                                required
                                value={amount}
                                onChange={w(setAmount)}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {currencyCode}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormControl>

                            <input
                              type="file"
                              id="file-input"
                              onChange={handleFileChange}
                              hidden
                            />
                            <Stack
                              direction="row"
                              flex
                              justifyContent="space-between"
                            >
                              {transaction.transactionType === 'DEPOSIT' ? (
                                <Stack direction="column" sx={{ mt: 3, mr: 3 }}>
                                  <Button
                                    variant="contained"
                                    disabled={isUploadingFiles}
                                    onClick={() =>
                                      document
                                        .getElementById('file-input')
                                        ?.click()
                                    }
                                  >
                                    Replace deposit slip
                                  </Button>
                                  <Typography fontWeight="bold">
                                    Accepted file formats are jpg and png.
                                  </Typography>
                                </Stack>
                              ) : (
                                <></>
                              )}
                              {!!depositSlip ? (
                                <img
                                  src={depositSlip}
                                  height="100px"
                                  alt=""
                                  border={1}
                                  style={{
                                    objectFit: 'scale-down',
                                    borderRadius: 4,
                                    color: 'primary',
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                            </Stack>

                            <FormControl fullWidth sx={{ my: 2 }}>
                              <TextField
                                id="transaction-id"
                                label="Transaction Id"
                                variant="outlined"
                                type="text"
                                required
                                value={transactionId}
                                onChange={w(setTransactionId)}
                              />
                            </FormControl>

                            <FormControl fullWidth sx={{ my: 2 }}>
                              <TextField
                                id="comment"
                                label="Comment"
                                variant="outlined"
                                type="text"
                                value={comment}
                                rows={3}
                                multiline
                                required
                                onChange={w(setComment)}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Button
                          variant="contained"
                          disabled={isSubmitting || isUploadingFiles}
                          sx={{ my: 2 }}
                          type="submit"
                        >
                          <span>Save</span>{' '}
                          {isSubmitting ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : (
                            <></>
                          )}
                        </Button>
                      </Box>
                    }
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
